<template>
  <v-container fluid>
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          SUBIENDO DATA...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row
      v-if="!carga"
      justify="center"
    >
      <div
        class="text-center"
      >
        <v-form
          ref="form"
          lazy-validation
        >
          <v-dialog
            v-model="dialogJuzgado"
            width="800"
            persistent
          >
            <v-card>
              <v-card-title class="text-h3 grey lighten-2">
                Datos de la meta
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-if="updateMetaAnual"
                      v-model="idJuzgado"
                      label="ID"
                      prepend-icon="mdi-fingerprint"
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-autocomplete
                      v-if="!updateMetaAnual"
                      v-model="idJuzgado"
                      :items="juzgadosMetaless"
                      item-text="nombre"
                      item-value="id_juzgado"
                      label="Seleccione el juzgado sin meta"
                    />
                    <v-text-field
                      v-else
                      v-model="nombreJuzgado"
                      label="Nombre"
                      prepend-icon="mdi-account-tie"
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="anoMeta"
                      label="Año"
                      prepend-icon="mdi-numeric"
                      :rules="[(v) => !!v || 'Campo requerido']"
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="metaJuzgado"
                      label="Meta anual"
                      prepend-icon="mdi-flag"
                      type="number"
                      :rules="[(v) => !!v || 'Campo requerido']"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />

              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  @click="addNewMeta()"
                >
                  {{ updateMetaAnual === true ? 'ACTUALIZAR' : 'AÑADIR' }}
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  @click="cerrarDialog()"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
      </div>
      <v-col
        style="text-align: right"
        cols="12"
        md="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Información sobre metas
            </div>
            <div class="text-subtitle-1 font-weight-light">
              {{ dataUsuario.nombre }}, actualice la información sobre las metas de los juzgados de su corte
            </div>
          </template>
          <br>
          <v-row justify="center">
            <v-col
              cols="12"
              md="9"
            >
              <v-card-title>
                <v-btn
                  color="red"
                  fab
                  x-small
                  dark
                  @click="addJuzgado()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn> <span class="text-subtitle-1 font-weight-light"> Nueva meta</span>
                <v-spacer />
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="ml-auto"
                  label="Buscar..."
                  hide-details
                  single-line
                  style="max-width: 250px;"
                />
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                dense
                class="elevation-1"
                fixed-header
                :loading="carga"
                loading-text="Cargando, por favor espere"
                :footer-props="{
                  'items-per-page-options': [10, 25, 50, -1],
                  'items-per-page-text': 'Mostrando:',
                  'items-per-page-all-text': 'Todos'
                }"
                no-data-text="No hay datos registrados"
                no-results-text="No se encontraron resultados"
              >
                <template v-slot:[`item.editar`]="{ item }">
                  <v-btn
                    color="green"
                    fab
                    x-small
                    dark
                    @click="editarMeta(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.sala`]="{ item }">
                  <span
                    v-if="parseInt(item.es_sala) === 1"
                  ><v-chip
                    class="ma-2"
                    color="green"
                    label
                    text-color="white"
                  >
                    <v-icon>
                      mdi-scale-balance
                    </v-icon>
                  </v-chip></span>
                  <span
                    v-else
                  ><v-chip
                    class="ma-2"
                    color="blue"
                    label
                    text-color="white"
                  >
                    <v-icon>
                      mdi-gavel
                    </v-icon>
                  </v-chip></span>
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-file-input
                v-model="archivoCsv2"
                :rules="reglaUpload"
                show-size
                placeholder="Adjunte el archivo de metas"
                prepend-icon="mdi-microsoft-excel"
                clear-icon
                label="Adjunte el archivo de metas"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              style="text-align: right"
            >
              <v-btn
                tile
                color="success"
                @click="updateResumen(archivoCsv2)"
              >
                <v-icon left>
                  mdi-upload
                </v-icon>
                Subir
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />
          <v-spacer />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import XLSX from 'xlsx'
  export default {
    name: 'Upload',
    data: () => ({
      reglaUpload: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          'El archivo no debe pesar más de 5 MB',
      ],
      usuario: '',
      nombreUsuario: '',
      mesUpdate: '',
      sedeCorteData: '',
      carga: false,
      mesesArray: [
        { value: 1, text: 'Enero' },
        { value: 2, text: 'Febrero' },
        { value: 3, text: 'Marzo' },
        { value: 4, text: 'Abril' },
        { value: 5, text: 'Mayo' },
        { value: 6, text: 'Junio' },
        { value: 7, text: 'Julio' },
        { value: 8, text: 'Agosto' },
        { value: 9, text: 'Setiembre' },
        { value: 10, text: 'Octubre' },
        { value: 11, text: 'Noviembre' },
        { value: 12, text: 'Diciembre' },
      ],
      archivoCsv: [],
      archivoCsv2: [],
      archivoCsv3: [],
      dataUpdate: [],
      urlDataSede: '',
      sumaI: 0,
      incorrectos: 0,
      urlLogo: '',
      urlMetas: '',
      items: [],
      dialogJuzgado: false,
      search: '',
      idJuzgado: '',
      nombreJuzgado: '',
      anoMeta: '',
      metaJuzgado: '',
      updateMetaAnual: false,
      textoBoton: 'AÑADIR',
      juzgadosMetaless: [],
      nuevaMeta: false,
      obj: {
        ano_meta: '',
        mensual: '',
        id_juzgado: '',
        id_meta: '',
      },
      headers: [
        {
          text: 'Editar',
          value: 'editar',
        },
        {
          text: 'Nombre',
          value: 'nombre',
        },
        {
          text: 'Año',
          value: 'ano_meta',
        },
        {
          text: 'Meta anual',
          value: 'anual',
        },
      ],
    }),
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (!this.dataUsuario || parseInt(this.dataUsuario.es_admin) !== 1) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.$store.commit('SET_VALOR', false)
      this.$store.commit('SET_LOGO', true)
      this.leerMetas()
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      cerrarSesion () {
        localStorage.setItem('sedeCorteData', null)
        localStorage.setItem('usuarioDataCSJLA', null)
        this.$router.push('/login')
      },
      addJuzgado () {
        this.updateMetaAnual = false
        this.nuevoJuzgado = true
        this.textoBoton = 'AÑADIR'
        this.dialogJuzgado = true
        this.leerMetaless()
        this.nuevaMeta = true
        this.anoMeta = new Date().getFullYear()
      },
      addNewMeta () {
        this.obj.ano_meta = this.anoMeta
        this.obj.anual = this.metaJuzgado
        this.obj.id_juzgado = this.idJuzgado
        this.updateMeta(this.obj, 1)
      },
      editarMeta (item) {
        if (item) {
          this.textoBoton = 'ACTUALIZAR'
          this.updateMetaAnual = true
          this.nuevaMeta = false
          this.dialogJuzgado = true
          this.idJuzgado = item.id_juzgado
          this.nombreJuzgado = item.nombre
          this.anoMeta = item.ano_meta
          this.metaJuzgado = item.anual
        }
      },
      leerMetas () {
        this.urlMetas = `${window.__env.dataCfg.urlApiData}metas/${new Date().getFullYear()}_${this.dataUsuario.sede}/`
        axios
          .get(
            this.urlMetas,
          )
          .then((response) => {
            this.items = response.data.array
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      },
      leerMetaless () {
        axios
          .get(
            `${window.__env.dataCfg.urlApiData}juzgados_nometa/`,
          )
          .then((response) => {
            this.juzgadosMetaless = response.data.array
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      },
      cerrarDialog () {
        this.dialogJuzgado = false
        this.nuevaMeta = false
        this.updateMetaAnual = false
        this.$refs.form.reset()
      },
      updateMeta (item, fin) {
        if (!item.ano_meta || !item.id_juzgado || (!item.anual || item.anual < 0) || !this.dataUsuario.sede) {
          alert('La fila ' + item.id_juzgado + ' no tiene todos los campos, por favor completar')
          this.incorrectos++
          if (this.incorrectos === parseInt(fin)) {
            alert('LAS ' + fin + ' FILAS DEBEN CORREGIRSE')
            this.carga = false
          }
        } else {
          const data = {
            ano_meta: item.ano_meta,
            id_juzgado: item.id_juzgado,
            mensual: (parseInt(item.anual) / 11),
            anual: item.anual,
            sede: this.dataUsuario.sede,
          }
          axios
            .post(`${window.__env.dataCfg.urlApiData}meta_juzgado/`, data)
            .then((r) => {
              if (!this.nuevaMeta) {
                if (this.updateMetaAnual) {
                  if (r.data.resp) {
                    alert('SE ACTUALIZÓ LA META CORRECTAMENTE')
                    this.cerrarDialog()
                    this.leerMetas()
                  } else {
                    alert('NO SE PUDO ACTUALIZAR LA META')
                  }
                } else {
                  this.sumaI++
                console.log(this.sumaI) // eslint-disable-line
                console.log(this.incorrectos) // eslint-disable-line
                console.log(fin) // eslint-disable-line
                  if (this.sumaI === (parseInt(fin) - this.incorrectos)) {
                    this.carga = false
                    alert('INFORMACIÓN DEL ARCHIVO SUBIDA, HUBIERON ' + this.incorrectos + ' FILAS INCOMPLETAS')
                    this.archivoCsv2 = []
                    this.incorrectos = 0
                    this.sumaI = 0
                    this.leerMetas()
                    this.obj = {}
                  }
                }
              } else {
                if (r.data.resp) {
                  alert('SE REGISTRÓ LA META CORRECTAMENTE')
                  this.cerrarDialog()
                  this.leerMetas()
                } else {
                  alert('HUBO UN ERROR EN EL REGISTRO')
                }
              }
            })
            .catch((err) => {
              alert('Servicio no disponible' + err)
            })
        }
      },
      updateResumen (e) {
        var reader = new FileReader()
        var arrayJuzgados = []
        var self = this
        reader.onload = function (e) {
          var data = new Uint8Array(e.target.result)
          var workbook = XLSX.read(data, { type: 'array' })
          const hoja1 = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[hoja1]
          arrayJuzgados = XLSX.utils.sheet_to_json(worksheet)
          for (var i = 0; i < arrayJuzgados.length; i++) {
            self.carga = true
            self.updateMeta(arrayJuzgados[i], arrayJuzgados.length)
          }
        }
        reader.readAsArrayBuffer(e)
      },
    },
  }
</script>
